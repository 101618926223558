<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />

      <!-- นำทาง -->
      <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
        <v-card-title style="height: 60px;">
          <h4> {{use_language.user}} </h4>
          <v-icon>mdi-chevron-right</v-icon>
          <span class="font-weight-regular body-1"> {{use_language.manage_users}} new </span>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <!-- แสดงภาพ -->
      <v-dialog v-model="ShowPhoto" max-width="500">
        <v-card class="ma-0">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
                <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- user -->
      <v-dialog v-model="staffShop.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title>
            <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
            <span v-if="!staffShop.isUpdate" class="ml-3">{{use_language.add_user}}</span>
            <span v-else class="ml-3">{{use_language.edit_user}}</span>
            <v-spacer/>
            <v-btn icon @click="CloseDialog()"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="overflow-y-auto px-5">

            <v-form ref="formStaffShop" lazy-validation>
              <v-row>
                <v-col cols="12" class="pt-1 pb-2">
                  <h3> {{use_language.username_password}} </h3>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">  {{use_language.picture_}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <a id="pick-avatar">
                    <v-avatar v-if="photo.photo_link!=null && photo.photo_link!=''" class="picsize-hover" tile>
                      <img :src="photo.photo_link" class="picsize">
                    </v-avatar>
                    <v-avatar v-else class="picsize-hover" tile>
                             {{use_language.no_picture}}

                    </v-avatar>
                  </a>
                  <avatar-cropper
                    @uploading="handleUploading"
                    @uploaded="handleUploaded"
                    @completed="handleCompleted"
                    @error="handlerError"
                    :cropper-options="cropperOptions"
                    :labels="textButton"
                    :upload-url="uploadurl"
                    trigger="#pick-avatar"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">         {{use_language.name}}: </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.name"
                    @change="value => staffShop.name = Trim_value(value)"
                    ref="staffShopname"
                    @keyup.enter="$refs.staffShopphone.focus()"
                    :rules="staffShop.rulesName"
                    autocomplete="off"
                    outlined
                    dense hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">         {{use_language.tel}}: </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.phone"
                    @change="value => staffShop.phone = Trim_value(value)"
                    ref="staffShopphone"
                    @keyup.enter="$refs.staffShopusername.focus()"
                    :rules="staffShop.rulesPhone"
                    autocomplete="off"
                    outlined
                    dense hide-details
                    maxlength="10"
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="staffShop.id==0">
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.username}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.username"
                    @change="value => staffShop.username = Trim_value(value)"
                    ref="staffShopusername"
                    @keyup.enter="$refs.staffShoppassword.focus()"
                    :rules="staffShop.rulesUsername"
                    autocomplete="off"
                    outlined
                    dense hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="staffShop.id==0">
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">  {{use_language.password}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.password"
                    @change="value => staffShop.password = Trim_value(value)"
                    ref="staffShoppassword"
                    @keyup.enter="$refs.staffShoppassword2.focus()"
                    :rules="staffShop.rulesPassword"
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="staffShop.id==0">
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.confirm_password}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.password2"
                    @change="value => staffShop.password2 = Trim_value(value)"
                    ref="staffShoppassword2"
                    :rules="staffShop.rulesPassword2"
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password2 ? 'text' : 'password'"
                    @click:append="show_password2 = !show_password2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.status}} : </span>
                </v-col>
                <v-col cols="12" md="1" class="pt-1 pb-2">
                  <v-switch v-model="staffShop.isActive" :disabled="staffShop.isReadonly" color="success" class="mx-2"></v-switch>
                </v-col>
                <v-col cols="12" md="8" class="pt-1 pb-2">
                  <span v-if="staffShop.isActive">{{use_language.enable}}</span>
                  <span v-else>{{use_language.disable}}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.main_user}} : </span>
                </v-col>
                <v-col cols="12" md="1" class="pt-1 pb-2">
                  <v-checkbox v-model="staffShop.isMasterUser" :disabled="staffShop.isReadonly" color="info"></v-checkbox>
                </v-col>
               
              </v-row>

              <v-row>
                <v-col cols="12" class="pt-1 pb-2">
                  <h3> {{use_language.user_rights}} </h3>
                </v-col>
              </v-row>
              <div v-if="!staffShop.isMasterUser">
                <v-row v-for="(item, index) in shopPermission" :key="index">
                  <v-col  cols="12" md="6" class="py-1">
                    <v-checkbox v-if="item.menuCode == 200 || item.menuCode == 300 || item.menuCode == 500 || item.menuCode == 600" :disabled="true" v-model="item.isActive" class="ml-4" color="success" :label="item.nameTh"></v-checkbox>
                    <v-checkbox v-else v-model="item.isActive" @change="check_select_menu(item)" class="ml-4" color="success" :label="item.nameTh"></v-checkbox>
                  </v-col>
                  <v-col  cols="12" md="6" class="py-1">
                    <v-select
                      v-if="item.menuCode != 200 && item.menuCode != 300 && item.menuCode != 500 && item.menuCode != 600"
                      :value="item.authorize"
                      :disabled="item.isActive == false"
                      @change="value => item.authorize = value"
                      autocomplete="off"
                      :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                      item-key="text"
                      item-value="value"
                      label=""
                      hide-details
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>

              </div>

              <div v-else>
                <v-row  v-for="(item, index) in shopPermission" :key="index">
                  <v-col  cols="12" md="6" class="py-1">
                    <v-checkbox input-value="true" :disabled="staffShop.isMasterUser" class="ml-4" color="success" :label="item.nameTh"></v-checkbox>
                  </v-col>
                  <v-col  cols="12" md="6" class="py-1">
                    <v-select
                      v-if="item.menuCode != 200 && item.menuCode != 300 && item.menuCode != 500 && item.menuCode != 600"
                      :value="2"
                      :disabled="staffShop.isMasterUser"
                      @change="value => item.authorize = value"
                      autocomplete="off"
                      :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                      item-key="text"
                      item-value="value"
                      label=""
                      hide-details
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </div>


            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-row >
              <v-col cols="12" class="pa-0" align="right">
                <v-btn :elevation="1" color="primary" @click="UpdateStaffShop()">
                  <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                  <span class="fn-14"><b> {{use_language.save}} </b></span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <v-dialog v-model="ChangePasswordDialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title>
            <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
           แก้ไขรหัสผ่าน
            <v-spacer/>
            <v-btn icon @click="CloseDialog1()"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="overflow-y-auto px-5">

            <v-form ref="formStaffShop1" lazy-validation>
              <v-row>
                <v-col cols="12" class="pt-1 pb-2">
                  <h3> {{use_language.username_password}} </h3>
                </v-col>
              </v-row>

          

              <v-row>
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">         {{use_language.name}}: </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  {{ staffShop.name }}
                </v-col>
              </v-row>
 
              <v-row  >
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.username}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                 
                  <v-text-field
                    :value="staffShop.username"
                    @change="value => staffShop.username = Trim_value(value)"
                    @keyup.enter="$refs.staffShoppassword3.focus()"
                    :rules="staffShop.rulesUsername"
                    autocomplete="off"
                    outlined
                    dense hide-details
                 
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14">  {{use_language.password}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.password"
                    @change="value => staffShop.password = Trim_value(value)"
                    ref="staffShoppassword3"
                    @keyup.enter="$refs.staffShoppassword4.focus()"
                    :rules="staffShop.rulesPassword3"
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    @click:append="show_password = !show_password"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="12" md="3" class="pt-1 pb-2">
                  <span class="fn-14"> {{use_language.confirm_password}} : </span>
                </v-col>
                <v-col cols="12" md="9" class="pt-1 pb-2">
                  <v-text-field
                    :value="staffShop.password2"
                    @change="value => staffShop.password2 = Trim_value(value)"
                    ref="staffShoppassword4"
                    :rules="staffShop.rulesPassword4"
                    autocomplete="off"
                    outlined
                    dense hide-details
                    :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password2 ? 'text' : 'password'"
                    @click:append="show_password2 = !show_password2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="pa-0" align="right">
                <v-btn :elevation="1" color="primary" @click="ChangePassword()">
                  <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                  <span class="fn-14"><b> {{use_language.save}} </b></span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>


      <v-card elevation="1">
        <v-card-title class="height-57">
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon><span class="ml-3"> {{use_language.user}}</span>
          <v-spacer/>
          <v-btn v-if="thisPagePermissionType==2" :elevation="1" color="primary" class="px-2" @click="OPPUser(false,null)">
            <v-icon size="22" class="mr-1">mdi-plus</v-icon> 
           {{use_language.add_user}}
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>

          <v-data-table class="packhai-border-table"
            :headers="header"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-footer
          >
            <template v-slot:item.photaLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize-35 mt-1">
              </a>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
              <v-chip v-if="item.isMasterUser" class="chip-set-13" color="teal" dark> {{use_language.master_user}} </v-chip>
            </template>
            <template v-slot:item.no="{ item }">
              {{ formatMoney(dataset.indexOf(item)+1) }}
            </template>
            <template v-slot:item.password="{ item }">
              ********
            </template>
            <template v-slot:item.edit="{ item }">
              <a v-if="thisPagePermissionType==2|| staff_id == item.id" @click="OPPUser(true,item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
            </template>
            <template v-slot:item.delete="{ item }">
              <a   v-if="thisPagePermissionType==2 && !item.isMasterUser" @click="DeleteUser(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
            </template>
            <template v-slot:item.changepassword="{ item }">
              <a v-if="thisPagePermissionType==2|| staff_id == item.id" @click="OpenChangePassword( item)">เปลี่ยนรหัสผ่าน</a>
            </template>
          </v-data-table>

        </v-card-text>
      </v-card>

    </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import AvatarCropper from "vue-avatar-cropper" 
  import { globalService, shopService_dotnet } from '@/website/global'
  import { formatMoney, isNumberWNoDot, isNumberWithDot, DateNowNotNull, Trim_value, Trim_rules ,get_languages} from '@/website/global_function'
  import { manageUser_language } from "@/website/language/manageUser_language";


 export default {
    components: {
      Loading,
      AvatarCropper
    },
    data: vm => ({
      thisPagePermissionType: parseInt(localStorage.getItem("400")),
      staff_id: parseInt(localStorage.getItem("staff_id")),
      page_loading: true,
      loading: false,
      use_language: null,
      set_language: null,
      ChangePasswordDialog:false,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',

      // user
      staffShop: {
        dialog: false,
        isUpdate: false,
        isReadonly: false,
        rulesName: [],
        rulesPhone: [],
        rulesUsername: [],
        rulesPassword: [],
        rulesPassword2: [],
        rulesPassword3:[],
        rulesPassword4:[],
        shopId: localStorage.getItem('shop_id'),
        id: 0,
        name: null,
        phone: null,
        username: null,
        password: null,
        password2: null,
        isMasterUser: false,
        isActive: true,
        photoLink: null,
      },
      show_password: false,
      show_password2: false,
      shopPermission: [],
      header: [
        { align: 'center', text: 'No', value: 'no', sortable: false, width: '60px'  },
        { align: 'center', text: 'รูปภาพ', value: 'photaLink', sortable: false, width: '70px' },
        { align: 'left', text: 'ชื่อ', value: 'name', sortable: false  },
        { align: 'center', text: 'เบอร์โทร', value: 'phone', sortable: false, width: '150px' },
        { align: 'center', text: 'ชื่อผู้ใช้', value: 'username', sortable: false, width: '150px'  }, 
        { align: 'center', text: 'แก้ไข', value: 'edit', sortable: false, width: '80px' },
        { align: 'center', text: 'ลบ', value: 'delete', sortable: false, width: '80px' },
        { align: 'center', text: 'เปลี่ยนรหัสผ่าน', value: 'changepassword', sortable: false, width: '120px' },
        
      ],
      dataset: [],

      // AvatarCropper
      cropperOptions:{
        background:false,
        viewMode:1,
        aspectRatio: 1,
        autoCropArea: 1,
        movable: false,
        zoomable: false
      },
      textButton: {
        submit:"ตกลง"
        ,cancel:"ยกเลิก"
      },
      uploadurl: globalService+"uploadPicture",
      message: "ready",
      photo: {
        "is_base": false,
        "photo_link": null,
        "photo_uploand": {
          "file":[],
          "fileSize":0,
          "from_Picture":{},
          "from_path":''
        },
      },
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')}

    }),
    computed: {
    },
    watch: {
    },
    async created() {
      this.GetStaffShop()

        //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));
          this.set_language=localStorage.getItem("set_language").toLowerCase();
 

      this.page_loading = false
    },
    methods: {
      get_languages,
      async GetStaffShop(){
        let response = await axios.post(shopService_dotnet+'StaffShop/get-staffshop-list', {
          "shopId": localStorage.getItem('shop_id')
        }, {headers: this.header_token})
        if (response.status ==200) {
          this.dataset = response.data
        }
      },
      async OpenChangePassword(item){
        this.staffShop.rulesPassword3 = [ v => !!v || 'กรุณาระบุ', ]
        this.staffShop.rulesPassword4 = [ v => !!v || 'กรุณาระบุ', ]
        this.staffShop.rulesUsername = [ v => !!v || 'กรุณาระบุ', ]
        
        this.staffShop.id = item.id
        this.staffShop.name = item.name 
        this.staffShop.username = item.username
        this.staffShop.password = null
        this.staffShop.password2 = null
        this.ChangePasswordDialog=true;
      },
      async ChangePassword(){
        
        if (this.$refs.formStaffShop1.validate()) {
          if (this.staffShop.password != this.staffShop.password2){
            Swal.fire({
              type: 'warning',
              title: 'รหัสผ่านไม่ตรงกัน',
              text: 'กรุณาลองใหม่อีกครั้ง',
            })
            return
          }

          let response = await axios.post(shopService_dotnet+'StaffShop/update-staffshop-password', {
            "staffShop": this.staffShop 
          },{headers: this.header_token})
          this.loading = false
           
          if (response.status==200)
          {
            if ( response.data.status=="error"){
             
              Swal.fire({ 
                type: "error",
                text:response.data.message[this.set_language],
              })
            }else{ 
              this.dataset = response.data.staffList
              this.ChangePasswordDialog = false
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'Success'
              })
            }
          } else {
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }
          
        }
      },
      async OPPUser(isUpdate,item){
        this.loading = true
        this.rulesStaffShop()

        // var permissionIndexList = [5,6,7,8,9]
        var permissionIndexList = []
        if (this.shopPermission.length==0){
          // โหลดครั้งแรก เพิ่มผู้ใช้ใหม่ แก้ไขผู้ใช้
          let response = await axios.post(shopService_dotnet+'StaffShop/get-permission-description', {}, {headers: this.header_token})
          if (response.status==200){
            for (var i in response.data){

              if(response.data[i].menuCode != null){
                response.data[i].isActive = false
                response.data[i].authorize = 1
                if (permissionIndexList.includes(parseInt(i))){
                  response.data[i].isActive = true
                }
                this.shopPermission.push(response.data[i])
              }

            }
          }
        } else {
          if (item==null){
            // เพิ่มผู้ใช้ใหม่
            for (var j in this.shopPermission){
              this.shopPermission[j].isActive = false
              this.shopPermission[j].authorize = 1
              if (permissionIndexList.includes(parseInt(j))){
                this.shopPermission[j].isActive = true

              }
            }
          }
        }

        if (!isUpdate){
          this.staffShop.id = 0
          this.staffShop.name = null
          this.staffShop.phone = null
          this.staffShop.username = null
          this.staffShop.password = null
          this.staffShop.password2 = null
          this.staffShop.isMasterUser = false
          this.staffShop.isActive = true
          this.staffShop.photoLink = null
          this.staffShop.isReadonly = false

          // ภาพ
          this.photo.photo_link = null
          this.photo.is_base = false

          var isMasterUser = false
          for (var a in this.dataset){
            if (this.dataset[a].isMasterUser){
              isMasterUser = true
              break
            }
          }
          if (isMasterUser){ this.staffShop.isMasterUser = !isMasterUser } else { this.staffShop.isMasterUser = !isMasterUser }

        } else {
          this.staffShop.id = item.id
          this.staffShop.name = item.name
          this.staffShop.phone = item.phone
          this.staffShop.username = item.username
          this.staffShop.password = item.password
          this.staffShop.password2 = item.password
          this.staffShop.isMasterUser = item.isMasterUser
          this.staffShop.isActive = item.isActive
          this.staffShop.photoLink = item.photoLink
          this.staffShop.isReadonly = item.isMasterUser ? true : false

          // ภาพ
          this.photo.photo_link = item.photoLink
          this.photo.is_base = true

          let response = await axios.post(shopService_dotnet+'StaffShop/get-staffshoppermission', {
            "staffShopId": item.id,
          }, {headers: this.header_token})
          if (response.status ==200) {
            // แก้ไขผู้ใช้
            for (var k in this.shopPermission){
              this.shopPermission[k].isActive = false
              this.shopPermission[k].authorize = 1
              for (var l in response.data){
                if (response.data[l].permissionID == this.shopPermission[k].id){
                  this.shopPermission[k].isActive = true
                  this.shopPermission[k].authorize = response.data[l].authorize
                  break
                }
              }
            }
          }
        }
        this.show_password = false,
        this.show_password2 = false,
        this.staffShop.isUpdate = isUpdate
        this.loading = false
        this.staffShop.dialog = true
      },
      async UpdateStaffShop(){
        await this.rulesStaffShopCheck()
        if (this.$refs.formStaffShop.validate()) {
          if (this.staffShop.password != this.staffShop.password2){
            Swal.fire({
              type: 'warning',
              title: 'รหัสผ่านไม่ตรงกัน',
              text: 'กรุณาลองใหม่อีกครั้ง',
            })
            return
          }
          this.loading = true
          if (this.staffShop.isMasterUser){
            this.staffShop.isActive = true
            for (var i in this.shopPermission){
              this.shopPermission[i].isActive = true
              this.shopPermission[i].authorize = 2
            }
          }
          // เช็คภาพ
          var photoLink_del = null
          if (this.photo.photo_link != this.staffShop.photoLink){
            photoLink_del = this.staffShop.photoLink
            this.staffShop.photoLink = this.photo.photo_link
          }

          let response = await axios.post(shopService_dotnet+'StaffShop/update-staffshop', {
            "staffShop": this.staffShop,
            "shopPermission": this.shopPermission,
          },{headers: this.header_token})
          this.loading = false
          if (response.status==200)
          {
            if ( response.data.status=="error"){
              Swal.fire({
                type: "error",
                text:response.data.message[this.set_language],
              })
            }else{
              // ลบ ภาพ
              if (photoLink_del!=null){ await this.DelPhoto(photoLink_del,false) }
              this.dataset = response.data.staffList
              this.staffShop.dialog = false
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'Success'
              })
            }
          } else {
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }
        }
      },
      async DeleteUser(item){
        Swal.fire({
          position: 'top',
          type: 'warning',
          text: item.name+ ' ลบ ใช่ หรือ ไม่',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {

            this.loading = true
            let response = await axios.post(shopService_dotnet+'StaffShop/delete-staffshop', {
              "staffShopId": item.id,
            }, {headers: this.header_token} )
            this.loading = false
            if (response.status == 200){
              this.dataset.splice(this.dataset.indexOf(item),1)
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'Success'
              })
            }else{
              Swal.fire({
                position: "top",
                type: 'error',
                text: 'ไม่สามารถทำรายการได้',
              })
            }

          }
        })
      },
      async CloseDialog(){
        await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
        this.staffShop.dialog = false
      },
      async CloseDialog1(){
       
        this.ChangePasswordDialog  = false
      },
      rulesStaffShopCheck(){ 
        if(this.staffShop.id==0){
          this.staffShop.rulesPhone = [ v => !!v || 'กรุณาระบุ', ]
          this.staffShop.rulesUsername = [ v => !!v || 'กรุณาระบุ', ]
          this.staffShop.rulesPassword = [ v => !!v || 'กรุณาระบุ', ]
          this.staffShop.rulesPassword2 = [ v => !!v || 'กรุณาระบุ', ]
        }else{
          this.staffShop.rulesName = [ v => !!v || 'กรุณาระบุ', ]
        }
       
    
      },
      rulesStaffShop(){
        this.staffShop.rulesName = []
        this.staffShop.rulesPhone = []
        this.staffShop.rulesUsername = []
        this.staffShop.rulesPassword = []
        this.staffShop.rulesPassword2 = []
      },

      // AvatarCropper
      async handleUploading(from_data, xhr) {
        var file_type = from_data.get("file").type.split("/")
        from_data.append("name", 'shop'+localStorage.getItem('shop_id')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1])
        from_data.append('path', 'User/')
        this.photo.photo_uploand['from_Picture'] = from_data
        this.photo.photo_uploand['from_path'] = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/User/'+'shop'+localStorage.getItem('shop_id')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1]
        this.message = "uploading...";
      },
      async handleUploaded(response, from_data, xhr) {
        if (response=="OK") {
          await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
          this.photo.photo_link = this.photo.photo_uploand['from_path']
          this.photo.is_base = false
          this.message = "user avatar updated.";
        }
      },
      handleCompleted(response, from_data, xhr) {
        this.message = "upload completed.";
      },
      handlerError(message, type, xhr) {
        this.message = "Oops! Something went wrong...";
      },
      async DelPhoto(photoLink,is_base){
        if (photoLink!='' && photoLink!=null && !is_base){
          var pathRemove = photoLink
          pathRemove = pathRemove.split("/");
          pathRemove = pathRemove[3]+'/'+pathRemove[4]
          let response = await axios.post(globalService+'removePicture', {'path':pathRemove})
        }
      },

      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },

      check_select_menu (item) {
        var IsHave200 = false
        var IsHave300 = false
        var IsHave500 = false
        var IsHave600 = false
        for (var item_b of this.shopPermission) {
          if(item.menuCode > 200 && item.menuCode < 300){
            IsHave200 = true
          }
          if(item.menuCode > 300 && item.menuCode < 400){
            IsHave300 = true
          }
          if(item.menuCode > 500 && item.menuCode < 600){
            IsHave500 = true
          }
          if(item.menuCode > 600 && item.menuCode < 700){
            IsHave600 = true
          }
        }

        for (var item_c of this.shopPermission) {
          if(IsHave200){
            if(item_c.menuCode == 200){
              item_c.isActive = true
            }
          }

          if(IsHave300){
            if(item_c.menuCode == 300){
              item_c.isActive = true
            }
          }
          if(IsHave500){
            if(item_c.menuCode == 500){
              item_c.isActive = true
            }
          }
          if(IsHave600){
            if(item_c.menuCode == 600){
              item_c.isActive = true
            }
          }
        }

        var DisIsHave200 = []
        var DisIsHave300 = []
        var DisIsHave500 = []
        var DisIsHave600 = []
        for (var item_r of this.shopPermission) {
          if(item_r.menuCode > 200 && item_r.menuCode < 300){
            if(item_r.isActive){
              DisIsHave200.push(item_r)
            }
          }
          if(item_r.menuCode > 300 && item_r.menuCode < 400){
            if(item_r.isActive){
              DisIsHave300.push(item_r)
            }
          }
          if(item_r.menuCode > 500 && item_r.menuCode < 600){
            if(item_r.isActive){
              DisIsHave500.push(item_r)
            }
          }
          if(item_r.menuCode > 600 && item_r.menuCode < 700){
            if(item_r.isActive){
              DisIsHave600.push(item_r)
            }
          }
        }

        for (var item_s of this.shopPermission) {
          if(DisIsHave200.length == 0){
            if(item_s.menuCode == 200){
              item_s.isActive = false
            }
          }

          if(DisIsHave300.length == 0){
            if(item_s.menuCode == 300){
              item_s.isActive = false
            }
          }

          if(DisIsHave500.length == 0){
            if(item_s.menuCode == 500){
              item_s.isActive = false
            }
          }

          if(DisIsHave600.length == 0){
            if(item_s.menuCode == 600){
              item_s.isActive = false
            }
          }
        }


      },
      formatMoney,
      isNumberWNoDot,
      isNumberWithDot,
      DateNowNotNull,
      Trim_value,
      Trim_rules
    }
  }
</script>
